<!--
 * @Description: 数字
 * @Author: 琢磨先生
 * @Date: 2024-05-06 09:30:41
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2025-03-02 10:04:14
-->
<template>
  <el-form-item
    :label="field.name"
    :class="`${field.is_required ? 'required' : ''}`"
  >
    <!-- <el-input
      v-model="textValue"
      placeholder="请输入手机号"
      type="mobile"
      @input="onChange"
      :style="field.style_percent ? `width:${field.style_percent}%` : ``"
    >
      <template #prefix>
        <el-icon><Iphone /></el-icon>
      </template>
    </el-input> -->
    <el-input
      v-model="textValue"
      placeholder=""
      @input="onChange" 
      :style="field.style_percent ? `width:${field.style_percent}%` : ``"
    ></el-input>
    <div class="help-block">{{ field.input_tips }}</div>
  </el-form-item>
</template>

<script>
export default {
  data() {
    return {
      textValue: '',
    };
  },
  props: {
    /**
     * 表单字段
     */
    field: {
      type: Object,
      default: {},
    },
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.textValue = this.value;
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onChange() {
      this.$emit("change", this.textValue);
    },
  },
};
</script>

<style>
.el-form-item.required .el-form-item__label {
  position: relative;
}
.el-form-item.required .el-form-item__label::before {
  content: "*";
  position: absolute;
  right: 5px;
  display: block;
  color: red;
}
</style>
