<template>
  <slot :row="item">
    <field-table-image
      v-if="field.type == 'image'"
      :value="value"
    ></field-table-image>
    <field-table-region
      v-else-if="field.type == 'region'"
      :value="value"
    ></field-table-region>
    <field-table-tag
      v-else-if="field.type == 'cust_tag'"
      :value="value"
    ></field-table-tag>
    <div v-else>
      {{ value }}
    </div>
  </slot>
</template>

<script>
export default {
  data() {
    return {
      item: {},
    };
  },
  props: {
    field: {
      type: Object,
      default: {},
    },

    value: {
      // type: Object,
      // default: "",
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    /**
     *
     */
    value: {
      handler(val) {
        if (val && /^\[.+\]$/.test(val)) {
          var m = JSON.parse(val);
          this.item = m;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
